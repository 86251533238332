.app-container {
  display: flex;
}

.side-menu-container {
  top: 100%; /* Open below the parent */
  width: 100%; /* Full width */
  background-color: #344955; /* Set your desired background color */
  z-index: 1000;
}

.side-menu {
  width: 100%;
  height: 100%;
  background: #344955;
  left: 100;
}

.right-side-menu {
  width: 100%; /* Adjust the width as needed */
  background-color: #4a6572; /* Set your desired background color */
  border-radius: 8px;
}

.right-side-menu-box {
  overflow: auto;
  max-height: 70%;
}

.main-content {
  flex-grow: 1;
}

.nav-link {
  text-decoration: none;
  color: #d9dce1;
  display: block;
  padding: 8px 16px;
  transition: background-color 0.3s;
  background-color: #4a6572;
  font-weight: 300;
}

.nav-link-right {
  text-decoration: none;
  color: #d9dce1;
  display: block;
  padding: 8px 16px;
  transition: background-color 0.3s;
  background-color: #4a6572;
  text-align: center;
  overflow: auto;
  font-weight: 300;
}

.nav-link-right:hover {
  transition: background-color 0.3s;
  background-color: #f9aa33;
  border-radius: 4px;
  color: black;
}

.nav-link:hover {
  background-color: #f9aa33;
  border-radius: 4px;
  color: black;

  /* Set your desired hover background color */
}

.right-menu-item {
  background-color: #4a6572; /* Set your desired background color */
  transition: background-color 0.3s;
  font-weight: 400;
  font-size: 16px;
}

.right-menu-item:hover {
  background-color: #f9aa33;
  border-radius: 4px;
  color: black;
  /* Set your desired hover background color */
}

.right-menu-item:hover .svgIconList {
  stroke: black;
  /* Set your desired hover background color */
}
