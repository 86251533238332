/* YourComponent.css */
.desktop-agro {
    display: flex;
    flex-direction: column;
    border-Right: 1px solid #ccc;
    padding: 12px;
    border-radius: 4px;
    margin-right: 4px;
  }

  .desktop-grid-agro { 
display: grid ;
grid-template-columns: 8% 92%;
 gap: 4px

  }
  
  .mobile-agro {
    display: none; /* default style for mobile */
  }
  
  @media (max-width: 1850px) {
    .desktop-agro {
      display: none; /* override style for mobile devices */
    }

    .desktop-grid-agro { 
        display: block
        
          }
  
    .mobile-agro {
      display: block; /* override style for desktop devices */
    }
  }
  

  