.app-container {
  display: flex;
}

.side-menu-container {
  display: flex;
  z-index: 999;
}

.side-menu {
  padding-top: 20px;
  width: 120px;
  height: 100vh;
  background: #344955;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  color: #d9dce1;
}

.right-side-menu {
  width: 160px; /* Adjust the width as needed */
  background-color: #4a6572; /* Set your desired background color */
  padding-top: 20px;
  border-radius: 8px;
}

.right-side-menu-box {
  overflow: auto;
  max-height: 70%;
}

.main-content {
  flex-grow: 1;
}

.nav-link {
  text-decoration: none;
  color: #d9dce1;
  display: block;
  padding: 8px 16px;
  transition: background-color 0.3s;
  background-color: #4a6572;
}

.nav-link-right {
  text-decoration: none;
  color: #d9dce1;
  display: block;
  padding: 8px 16px;
  transition: background-color 0.3s;
  background-color: #4a6572;
  text-align: center;
  overflow: auto;
}

.nav-link-right:hover {
  transition: background-color 0.3s;
  background-color: #f9aa33;
  border-radius: 4px;
}

.nav-link:hover {
  background-color: #f9aa33;
  border-radius: 4px;
  /* Set your desired hover background color */
}

.right-menu-item {
  background-color: #4a6572; /* Set your desired background color */
  transition: background-color 0.3s;
  font-weight: 400;
  font-size: 16px;
}

.right-menu-item:hover {
  background-color: #f9aa33;
  border-radius: 4px;
  /* Set your desired hover background color */
}

.svgIconList {
  width: 20px;

  stroke: #d9dce1;
}

.svgIconList:hover {
  width: 20px;
  stroke: #000;
}
